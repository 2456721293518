<template>
  <div>
    <vs-row vs-type="flex" vs-justify="center">
      <div class="px-3">
        <vs-radio
          @change="
            contrato.fog_inicial = 0;
            contrato.carros = 1;
          "
          class="mx-1"
          color="success"
          marked
          v-model="contrato.fog"
          vs-name="fog"
          vs-value="1"
          >FOG</vs-radio
        >
        <vs-radio
          @change="
            contrato.fog = 0;
            contrato.carros = 1;
          "
          class="mx-1"
          color="success"
          v-model="contrato.fog_inicial"
          vs-name="fog"
          vs-value="1"
          >FOG INCIAL</vs-radio
        >
        <vs-radio
          @change="
            contrato.fog = 0;
            contrato.fog_inicial = 0;
          "
          class="mx-1"
          color="success"
          marked
          vs-name="fog"
          vs-value="1"
          >NENHUM</vs-radio
        >
      </div>
      <div class="px-3">
        <vs-radio
          class="mx-1"
          @change="
            contrato.escada_inicial = 0;
            contrato.carros = 1;
          "
          color="success"
          v-model="contrato.escada"
          vs-name="escada"
          vs-value="1"
          >ESCADA</vs-radio
        >
        <vs-radio
          class="mx-1"
          @change="
            contrato.escada = 0;
            contrato.carros = 1;
          "
          color="success"
          v-model="contrato.escada_inicial"
          vs-name="escada"
          vs-value="1"
          >ESCADA INCIAL</vs-radio
        >
        <vs-radio
          class="mx-1"
          @change="
            contrato.escada = 0;
            contrato.escada_inicial = 0;
          "
          color="success"
          vs-name="escada"
          vs-value="1"
          >NENHUM</vs-radio
        >
      </div>
    </vs-row>
    <vs-row vs-type="flex" vs-justify="center">
      <div class="px-3">
        <vs-input-number
          class="mt-3"
          color="success"
          v-validate="'required|numeric'"
          label="Dispositivos."
          min="0"
          v-model="contrato.modulos"
          name="modulos"
          @change="update()"
        />
      </div>

      <div class="px-3">
        <vs-input-number
          class="mt-3"
          color="success"
          v-validate="'required|numeric'"
          label="Armadilhas."
          min="0"
          v-model="contrato.armadilhas"
          name="armadilhas"
          @change="update()"
        />
      </div>
      <div class="px-3">
        <vs-input-number
          class="mt-3"
          color="success"
          v-validate="'required|numeric'"
          label="Carros."
          min="0"
          v-model="contrato.carros"
          name="carros"
          @change="update()"
        />
      </div>
    </vs-row>
  </div>
</template>
<script>
export default {
  props: {
    contrato: {
      type: Object,
    },
  },
  methods: {
    async update() {
      this.$emit("update", this.contrato);
    },
  },
};
</script>
