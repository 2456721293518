<template>
  <div class="w-full">
    <vs-row vs-type="flex" vs-align="center" vs-justify="center">
      <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
        <vs-button
          color="#ffa500"
          icon="phone_missed"
          @click="$atividades.permissoes.includes(2) ? (openModal = true) : ''"
          :disabled="!$atividades.permissoes.includes(2)"
          class="font-bold"
          >REAGENDAR CLIENTE</vs-button
        >
      </vs-col>
    </vs-row>
    <vs-popup
      :title="'Reagendar prospecção: ' + item.nome_fantasia"
      :active.sync="openModal"
    >
      <div
        class="w-full vs-con-loading__container"
        :id="'loadingReagenda' + item.id"
      >
        <vs-row vs-type="flex" vs-align="center" vs-justify="flex-start">
          <vs-col vs-w="6" vs-type="flex" vs-justify="center" vs-align="center">
            <div class="w-full mb-3 mx-1">
              <label style="color: black; font-size: 0.85rem" class="ml-1"
                >Motivo</label
              >
              <el-select
                filterable
                clearable
                class="w-full"
                placeholder="Selecione..."
                name="motivo"
                v-validate="'required'"
                v-model="obj.id_frase"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="frase in frasesDeclinio"
                  :value="frase.id"
                  :label="frase.frase"
                  :key="frase.id"
                >
                </el-option>
              </el-select>
              <vs-row>
                <span class="text-danger text-sm" v-show="errors.has('motivo')">
                  {{ $validators.empty }}</span
                >
              </vs-row>
            </div>
          </vs-col>
          <vs-col vs-w="6" vs-type="flex" vs-justify="center" vs-align="center">
            <div class="w-full mb-3 mx-1">
              <vs-input
                label="Data Próximo Contato"
                class="w-full"
                type="date"
                name="Data"
                v-model="obj.data_proximo_contato"
              />
            </div>
          </vs-col>
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <div class="w-full mx-1">
              <vs-textarea
                v-model="obj.obs"
                label="Observação do Reagendamento"
              />
            </div>
          </vs-col>

          <vs-col
            vs-w="7"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            class="p-2"
          >
            <vx-card class="reagendarConf overflow-hidden" @click="reagendar()">
              <template slot="no-body">
                <div class="item-details px-2">
                  <div class="my-1">
                    <vs-col
                      vs-w="12"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      class=""
                    >
                      <span class="my-1" style="font-size: 20px"
                        >CONFIRMAR REAGENDA</span
                      >
                    </vs-col>
                  </div>
                </div>
              </template>
            </vx-card>
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      required: true,
    },
  },
  data() {
    return {
      openModal: false,
      obj: {
        id_status: 9,
        data_proximo_contato: null,
        obs: "",
        id_frase: null,
      },
      frasesDeclinio: [],
    };
  },
  methods: {
    async close() {
      this.openModal = await false;
    },
    async reagendar() {
      await this.$vs.loading({
        container: "#loadingReagenda" + this.item.id,
        scale: 0.6,
      });
      try {
        const res = await this.$http.put(`cliente/` + this.item.id, this.obj);
        await this.logAssunto(res);
        await this.close();
        await this.$vs.notify(this.$notify.Success);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.close();
        await this.$emit("update");
        await this.$vs.loading.close(
          "#loadingReagenda" + this.item.id + " > .con-vs-loading"
        );
      }
    },
    async logAssunto(res) {
      try {
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Reagendamento da prospecção N°" + res.id,
        };
        await this.$logger(logData);
        await this.$http.post("assuntoCliente", {
          id_assuntos: 2,
          id_cliente: res.id,
          id_estagio: 1,
          status: 1,
          tipo: res.id_tipo_contato,
          data_marcada: this.obj.data_proximo_contato,
          id_colaborador: window.localStorage.getItem("id"),
          obs: this.obj.obs,
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getFrasesDeclinio() {
      try {
        this.frasesDeclinio = await this.$http.get(`frasesDeclinio`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
  },
  async mounted() {
    await this.getFrasesDeclinio();
  },
};
</script>
<style lang="scss" scoped>
.grid-view-item {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #00bba2;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.reagendar {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: orange;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.reagendarConf {
  background-color: orange;
  color: white;
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: orange;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.sem-autorizacao {
  background-color: #aaa;
  color: white;
  .img {
    color: white !important;
  }
}
</style>
