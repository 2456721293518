<template>
  <div>
    <vx-card>
      <div class="vx-row">
        <div class="vx-col w-3/4 flex">
          <form-wizard
            transition="fade"
            stepSize="sm"
            :start-index="
              $route.query.wizardTab != undefined
                ? parseInt($route.query.wizardTab)
                : 0
            "
            class="w-full py-0 my-0"
            style="height: 100%"
            color="rgba(var(--vs-dark), 1)"
            errorColor="rgba(var(--vs-danger), 1)"
            :title="null"
            :subtitle="null"
            finishButtonText="Finalizar"
            nextButtonText="Avançar"
            backButtonText="Voltar"
            @on-complete="confirmarProspeccao"
          >
            <!-- SEGMENTO-->
            <tab-content
              :class="tabClass"
              id="tabwiz"
              title="Segmento"
              :before-change="validarSegmento"
            >
              <form data-vv-scope="step-1">
                <div class="flex justify-center mb-6">
                  <vs-divider color="primary" class="py-0 my-1">
                    <span style="font-size: 30px">SEGMENTO</span>
                  </vs-divider>
                </div>
                <div class="flex justify-center" id="sidebar">
                  <vs-row>
                    <vs-col
                      vs-w="12"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vs-row>
                        <vs-col
                          vs-w="3"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          class="px-2"
                        >
                          <div class="w-full">
                            <label style="color: black; font-size: 13px"
                              >Tipo pessoa</label
                            >
                            <el-select
                              filterable
                              clearable
                              class="w-full"
                              placeholder="Tipo da Pessoa"
                              v-model="cliente.tipo_pessoa"
                            >
                              <el-option
                                v-for="opcoes in tipo_pessoa"
                                class="select-info"
                                :key="opcoes.id"
                                :label="opcoes.nome"
                                :value="opcoes.id"
                              ></el-option>
                            </el-select>
                          </div>
                        </vs-col>
                        <vs-col
                          vs-w="3"
                          class="px-2"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <div class="w-full">
                            <label style="color: black; font-size: 13px"
                              >Empresa</label
                            >
                            <el-select
                              filterable
                              clearable
                              class="select-info"
                              placeholder="Empresa"
                              v-model="prospeccao.id_empresa"
                            >
                              <el-option
                                v-for="empresa in empresas"
                                class="select-info"
                                :value="empresa.id"
                                :label="empresa.razao_social"
                                :key="empresa.id"
                              ></el-option>
                            </el-select>
                          </div>
                        </vs-col>
                        <vs-col
                          vs-w="3"
                          class="px-2"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <div class="w-full">
                            <label style="color: black; font-size: 13px"
                              >Segmento</label
                            >
                            <el-select
                              filterable
                              clearable
                              class="w-full"
                              placeholder="Segmento"
                              @change="changeSegmento($event)"
                              v-model="prospeccao.id_segmento"
                            >
                              <el-option
                                v-for="segmento in segmentos"
                                class="select-info"
                                :value="segmento.id"
                                :label="segmento.nome"
                                :key="segmento.id"
                              ></el-option>
                            </el-select>
                          </div>
                        </vs-col>
                        <vs-col
                          vs-w="3"
                          class="px-2"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <div class="w-full">
                            <label style="color: black; font-size: 13px"
                              >Proveniente</label
                            >
                            <el-select
                              filterable
                              clearable
                              class="w-full"
                              placeholder="Proviniente"
                              v-model="cliente.id_proviniente"
                            >
                              <el-option
                                v-for="proveniente in provenientes"
                                class="select-info"
                                :value="proveniente.id"
                                :label="proveniente.nome"
                                :key="proveniente.id"
                              ></el-option>
                            </el-select>
                          </div>
                        </vs-col>
                        <vs-row
                          vs-w="12"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-divider class="mt-6" border-style="dotted">
                            <div class="mx-2 w-full">
                              <vs-col
                                vs-w="10"
                                vs-type="flex"
                                vs-justify="center"
                                vs-align="center"
                                >Tipo de Interação</vs-col
                              >
                              <vs-col
                                vs-w="2"
                                vs-type="flex"
                                vs-justify="center"
                                vs-align="center"
                              >
                                <vx-tooltip
                                  color="danger"
                                  text="Tipo de Interação com o Cliente"
                                >
                                  <vs-icon
                                    style="cursor: pointer"
                                    icon="info"
                                  ></vs-icon>
                                </vx-tooltip>
                              </vs-col>
                            </div>
                          </vs-divider>
                        </vs-row>
                        <vs-col
                          vs-w="12"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <div class="w-full" :key="reRender">
                            <selectTipoLigacao
                              :opcao="cliente.id_tipo_contato"
                              @select-interaction="selectInteraction"
                            ></selectTipoLigacao>
                          </div>
                        </vs-col>
                      </vs-row>
                    </vs-col>
                  </vs-row>
                </div>
                <vs-row vs-type="flex" vs-align="center" vs-justify="center">
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-divider class="mt-6" border-style="dotted"></vs-divider>
                  </vs-col>
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <reagendarProspeccaoModal
                      @update="goTo()"
                      :item="cliente"
                    ></reagendarProspeccaoModal>
                  </vs-col>
                </vs-row>
              </form>
            </tab-content>
            <!-- TIPO DE CONTROLE-->
            <tab-content
              id="tabwiz2"
              :class="tabClass"
              title="Controle"
              :before-change="validarControle"
            >
              <form data-vv-scope="step-2">
                <div class="flex justify-center mb-6">
                  <vs-divider color="primary" class="py-0 my-1">
                    <span style="font-size: 30px">CONTROLE</span>
                  </vs-divider>
                </div>
                <vs-row>
                  <vs-col
                    vs-w="12"
                    class="px-2"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <div style="display: inline-block; margin-right: 20px">
                      <label style="color: black; font-size: 13px"
                        >Controles</label
                      >
                      <br />
                      <el-select
                        filterable
                        clearable
                        class="select-info"
                        placeholder="Controle"
                        @change="changeControle($event)"
                        v-model="prospeccao.id_controle"
                      >
                        <el-option
                          v-for="controle in controles"
                          class="select-info"
                          :value="controle.id"
                          :label="controle.nome"
                          :key="controle.id"
                        ></el-option>
                      </el-select>
                    </div>
                  </vs-col>
                </vs-row>
                <div class="w-full mt-10">
                  <vs-textarea
                    color="success"
                    label="Observação"
                    name="obsControle"
                    v-model="prospeccao.obs"
                  />
                </div>
              </form>
            </tab-content>
            <!-- AREAS-->
            <tab-content id="tabwiz3" :class="tabClass" title="Areas">
              <form data-vv-scope="step-3">
                <div class="flex justify-center mb-6">
                  <vs-divider color="primary" class="py-0 my-0">
                    <span style="font-size: 30px">AREAS</span>
                  </vs-divider>
                </div>
                <div class>
                  <Extras
                    @update="updateExtras"
                    :contrato="prospeccao"
                  ></Extras>
                  <Areas
                    :key="reRenderLev"
                    :id="prospeccao.id"
                    :areasLevantamento="areasLevantamento"
                    @addAreas="atualizarAreasSelecionadas"
                  ></Areas>
                </div>
              </form>
            </tab-content>

            <!-- ENDERECO-->
            <tab-content
              :class="tabClass"
              id="tabwiz5"
              title="Endereco"
              :before-change="validarEndereco"
            >
              <form data-vv-scope="step-4">
                <div class="flex justify-center">
                  <vs-divider color="primary" class="py-0 my-1">
                    <span style="font-size: 30px">ENDERECO</span>
                  </vs-divider>
                </div>
                <div class>
                  <EditEndereco
                    size="small"
                    :key="reRender"
                    :blockLenvNovoModal="false"
                    @removLev="removLev"
                    @addLev="saveLev"
                    @editEndereco="saveEndereco"
                    :cliente="cliente"
                    :pop="true"
                  ></EditEndereco>
                </div>
              </form>
            </tab-content>
            <!-- PLANOS-->
            <tab-content
              :before-change="validarPlanos"
              v-if="!(levantamentos.length > 0)"
              :class="tabClass"
              id="tabwiz6"
              title="Planos"
            >
              <form data-vv-scope="step-5">
                <div class="flex justify-center mb-0">
                  <vs-divider color="primary" class="py-0 my-1">
                    <span style="font-size: 30px">PLANOS</span>
                  </vs-divider>
                </div>
                <div class>
                  <VuePerfectScrollbar
                    class="scroll-planos-prospeccao"
                    :settings="settings"
                  >
                    <div v-if="cliente.tipo_pessoa == 1">
                      <div>
                        <vs-row
                          vs-type="flex"
                          vs-justify="flex-start"
                          vs-align="flex-start"
                          class="py-0 my-0"
                        >
                          <vs-divider
                            border-style="dotted"
                            color="danger"
                            class="py-0 my-1"
                          >
                            <span style="font-size: 15px">TRIMESTRAL</span>
                          </vs-divider>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Tecnicos inicias"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoTrimestral.tecnicos_iniciais"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Horas inicias"
                                icon="HRS"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoTrimestral.horas_iniciais"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2.3"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Tecnicos manutencao"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoTrimestral.tecnicos_manutencao"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Horas manutencao"
                                icon="HRS"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoTrimestral.horas_manutencao"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                          >
                            <vx-input-group>
                              <vs-input
                                label="Dias entre manutenções"
                                icon="DIA"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                mas="25"
                                v-validate="'required'"
                                v-model="planoTrimestral.periodo_manutencao"
                              ></vs-input>
                            </vx-input-group>
                          </vs-col>
                        </vs-row>
                        <vs-row
                          vs-type="flex"
                          vs-justify="flex-start"
                          vs-align="flex-start"
                          class="py-0 my-0"
                        >
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Aplicações"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoTrimestral.aplicacoes"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Garantia"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="0"
                                max="25"
                                v-model="planoTrimestral.garantia"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Validade"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoTrimestral.validade"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Desconto"
                                :key="reRenderDesconto"
                                @change="
                                  verifyDesconto(
                                    limites_desconto[
                                      planoTrimestral.id_tipo_faturamento - 1
                                    ].limite_desconto,
                                    planoTrimestral
                                  )
                                "
                                icon="%"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="0"
                                :max="
                                  limites_desconto.length > 0 &&
                                  limites_desconto != undefined
                                    ? limites_desconto[
                                        planoTrimestral.id_tipo_faturamento - 1
                                      ].limite_desconto
                                    : 0
                                "
                                v-model="planoTrimestral.desconto"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Parcelas"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoTrimestral.parcelas"
                              />
                            </vx-input-group>
                          </vs-col>
                        </vs-row>
                      </div>
                      <div>
                        <vs-row
                          vs-type="flex"
                          vs-justify="flex-start"
                          vs-align="flex-start"
                          class="py-0 my-0"
                        >
                          <vs-divider
                            border-style="dotted"
                            color="danger"
                            class="py-0 my-1"
                          >
                            <span style="font-size: 15px">SEMESTRAL</span>
                          </vs-divider>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Tecnicos inicias"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoSemestral.tecnicos_iniciais"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Horas inicias"
                                icon="HRS"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoSemestral.horas_iniciais"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2.3"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Tecnicos manutencao"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoSemestral.tecnicos_manutencao"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Horas manutencao"
                                icon="HRS"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoSemestral.horas_manutencao"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                          >
                            <vx-input-group>
                              <vs-input
                                label="Dias entre manutenções"
                                icon="DIA"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                mas="25"
                                v-validate="'required'"
                                v-model="planoSemestral.periodo_manutencao"
                              ></vs-input>
                            </vx-input-group>
                          </vs-col>
                        </vs-row>
                        <vs-row
                          vs-type="flex"
                          vs-justify="flex-start"
                          vs-align="flex-start"
                          class="py-0 my-0"
                        >
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Aplicações"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoSemestral.aplicacoes"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Garantia"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="0"
                                max="25"
                                v-model="planoSemestral.garantia"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Validade"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoSemestral.validade"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Desconto"
                                :key="reRenderDesconto"
                                @change="
                                  verifyDesconto(
                                    limites_desconto[
                                      planoSemestral.id_tipo_faturamento - 1
                                    ].limite_desconto,
                                    planoSemestral
                                  )
                                "
                                icon="%"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="0"
                                :max="
                                  limites_desconto.length > 0 &&
                                  limites_desconto != undefined
                                    ? limites_desconto[
                                        planoSemestral.id_tipo_faturamento - 1
                                      ].limite_desconto
                                    : 0
                                "
                                v-model="planoSemestral.desconto"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Parcelas"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoSemestral.parcelas"
                              />
                            </vx-input-group>
                          </vs-col>
                        </vs-row>
                      </div>
                      <div>
                        <vs-row
                          vs-type="flex"
                          vs-justify="flex-start"
                          vs-align="flex-start"
                          class="py-0 my-0"
                        >
                          <vs-divider
                            border-style="dotted"
                            color="danger"
                            class="py-0 my-1"
                          >
                            <span style="font-size: 15px">ANUAL</span>
                          </vs-divider>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Tecnicos inicias"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoAnualFisico.tecnicos_iniciais"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Horas inicias"
                                icon="HRS"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoAnualFisico.horas_iniciais"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2.3"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Tecnicos manutencao"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoAnualFisico.tecnicos_manutencao"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Horas manutencao"
                                icon="HRS"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoAnualFisico.horas_manutencao"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                          >
                            <vx-input-group>
                              <vs-input
                                label="Dias entre manutenções"
                                icon="DIA"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                mas="25"
                                v-validate="'required'"
                                v-model="planoAnualFisico.periodo_manutencao"
                              ></vs-input>
                            </vx-input-group>
                          </vs-col>
                        </vs-row>
                        <vs-row
                          vs-type="flex"
                          vs-justify="flex-start"
                          vs-align="flex-start"
                          class="py-0 my-0"
                        >
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Aplicações"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoAnualFisico.aplicacoes"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Garantia"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="0"
                                max="25"
                                v-model="planoAnualFisico.garantia"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Validade"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoAnualFisico.validade"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Desconto"
                                :key="reRenderDesconto"
                                @change="
                                  verifyDesconto(
                                    limites_desconto[
                                      planoAnualFisico.id_tipo_faturamento - 1
                                    ].limite_desconto,
                                    planoAnualFisico
                                  )
                                "
                                icon="%"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="0"
                                :max="
                                  limites_desconto.length > 0 &&
                                  limites_desconto != undefined
                                    ? limites_desconto[
                                        planoAnualFisico.id_tipo_faturamento - 1
                                      ].limite_desconto
                                    : 0
                                "
                                v-model="planoAnualFisico.desconto"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Parcelas"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoAnualFisico.parcelas"
                              />
                            </vx-input-group>
                          </vs-col>
                        </vs-row>
                      </div>
                    </div>
                    <div v-else>
                      <div>
                        <vs-row
                          vs-type="flex"
                          vs-justify="flex-start"
                          vs-align="flex-start"
                          class="py-0 my-0"
                        >
                          <vs-divider
                            border-style="dotted"
                            color="danger"
                            class="py-0 my-1"
                          >
                            <span style="font-size: 15px">MENSAL</span>
                          </vs-divider>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Tecnicos inicias"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoMensal.tecnicos_iniciais"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Horas inicias"
                                icon="HRS"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoMensal.horas_iniciais"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2.3"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Tecnicos manutencao"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoMensal.tecnicos_manutencao"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Horas manutencao"
                                icon="HRS"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoMensal.horas_manutencao"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                          >
                            <vx-input-group>
                              <vs-input
                                label="Dias entre manutenções"
                                icon="DIA"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                mas="25"
                                v-validate="'required'"
                                v-model="planoMensal.periodo_manutencao"
                              ></vs-input>
                            </vx-input-group>
                          </vs-col>
                        </vs-row>
                        <vs-row
                          vs-type="flex"
                          vs-justify="flex-start"
                          vs-align="flex-start"
                          class="py-0 my-0"
                        >
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Aplicações"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoMensal.aplicacoes"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Garantia"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="0"
                                max="25"
                                v-model="planoMensal.garantia"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Validade"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoMensal.validade"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Desconto"
                                :key="reRenderDesconto"
                                @change="
                                  verifyDesconto(
                                    limites_desconto[
                                      planoMensal.id_tipo_faturamento - 1
                                    ].limite_desconto,
                                    planoMensal
                                  )
                                "
                                icon="%"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="0"
                                :max="
                                  limites_desconto.length > 0 &&
                                  limites_desconto != undefined
                                    ? limites_desconto[
                                        planoMensal.id_tipo_faturamento - 1
                                      ].limite_desconto
                                    : 0
                                "
                                v-model="planoMensal.desconto"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vx-input-group class="mx-2">
                            <vs-input
                              label="Parcelas"
                              icon="QTD"
                              icon-after="true"
                              type="number"
                              color="success"
                              min="1"
                              max="25"
                              v-validate="'required'"
                              v-model="planoMensal.parcelas"
                            />
                          </vx-input-group>
                        </vs-row>
                      </div>
                      <div>
                        <vs-row
                          vs-type="flex"
                          vs-justify="flex-start"
                          vs-align="flex-start"
                          class="py-0 my-0"
                        >
                          <vs-divider
                            border-style="dotted"
                            color="danger"
                            class="py-0 my-1"
                          >
                            <span style="font-size: 15px">ANUAL</span>
                          </vs-divider>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Tecnicos inicias"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoAnualJuridico.tecnicos_iniciais"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Horas inicias"
                                icon="HRS"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoAnualJuridico.horas_iniciais"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2.3"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Tecnicos manutencao"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoAnualJuridico.tecnicos_manutencao"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Horas manutencao"
                                icon="HRS"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoAnualJuridico.horas_manutencao"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                          >
                            <vx-input-group>
                              <vs-input
                                label="Dias entre manutenções"
                                icon="DIA"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                mas="25"
                                v-validate="'required'"
                                v-model="planoAnualJuridico.periodo_manutencao"
                              ></vs-input>
                            </vx-input-group>
                          </vs-col>
                        </vs-row>
                        <vs-row
                          vs-type="flex"
                          vs-justify="flex-start"
                          vs-align="flex-start"
                          class="py-0 my-0"
                        >
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Aplicações"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoAnualJuridico.aplicacoes"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Garantia"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="0"
                                max="25"
                                v-model="planoAnualJuridico.garantia"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Validade"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoAnualJuridico.validade"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Desconto"
                                :key="reRenderDesconto"
                                @change="
                                  verifyDesconto(
                                    limites_desconto[
                                      planoAnualJuridico.id_tipo_faturamento - 1
                                    ].limite_desconto,
                                    planoAnualJuridico
                                  )
                                "
                                icon="%"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="0"
                                :max="
                                  limites_desconto.length > 0 &&
                                  limites_desconto != undefined
                                    ? limites_desconto[
                                        planoAnualJuridico.id_tipo_faturamento -
                                          1
                                      ].limite_desconto
                                    : 0
                                "
                                v-model="planoAnualJuridico.desconto"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vx-input-group class="mx-2">
                            <vs-input
                              label="Parcelas"
                              icon="QTD"
                              icon-after="true"
                              type="number"
                              color="success"
                              min="1"
                              max="25"
                              v-validate="'required'"
                              v-model="planoAnualJuridico.parcelas"
                            />
                          </vx-input-group>
                        </vs-row>
                      </div>
                      <div>
                        <vs-row
                          vs-type="flex"
                          vs-justify="flex-start"
                          vs-align="flex-start"
                          class="py-0 my-0"
                        >
                          <vs-divider
                            border-style="dotted"
                            color="danger"
                            class="py-0 my-1"
                          >
                            <span style="font-size: 15px">GLOBAL</span>
                          </vs-divider>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Tecnicos inicias"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoGlobal.tecnicos_iniciais"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Horas inicias"
                                icon="HRS"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoGlobal.horas_iniciais"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2.3"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Tecnicos manutencao"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoGlobal.tecnicos_manutencao"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Horas manutencao"
                                icon="HRS"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoGlobal.horas_manutencao"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                          >
                            <vx-input-group class>
                              <vs-input
                                label="Dias entre manutenções"
                                icon="DIA"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                mas="25"
                                v-validate="'required'"
                                v-model="planoGlobal.periodo_manutencao"
                              ></vs-input>
                            </vx-input-group>
                          </vs-col>
                        </vs-row>
                        <vs-row
                          vs-type="flex"
                          vs-justify="flex-start"
                          vs-align="flex-start"
                          class="py-0 my-0"
                        >
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Aplicações"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoGlobal.aplicacoes"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Garantia"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="0"
                                max="25"
                                v-model="planoGlobal.garantia"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Validade"
                                icon="QTD"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="1"
                                max="25"
                                v-validate="'required'"
                                v-model="planoGlobal.validade"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vs-col
                            vs-w="2"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            class="py-0 my-0"
                          >
                            <vx-input-group class="mx-2">
                              <vs-input
                                label="Desconto"
                                :key="reRenderDesconto"
                                @change="
                                  verifyDesconto(
                                    limites_desconto[
                                      planoGlobal.id_tipo_faturamento - 1
                                    ].limite_desconto,
                                    planoGlobal
                                  )
                                "
                                icon="%"
                                icon-after="true"
                                type="number"
                                color="success"
                                min="0"
                                :max="
                                  limites_desconto.length > 0 &&
                                  limites_desconto != undefined
                                    ? limites_desconto[
                                        planoGlobal.id_tipo_faturamento - 1
                                      ].limite_desconto
                                    : 0
                                "
                                v-model="planoGlobal.desconto"
                              />
                            </vx-input-group>
                          </vs-col>
                          <vx-input-group class="mx-2">
                            <vs-input
                              label="Parcelas"
                              icon="QTD"
                              icon-after="true"
                              type="number"
                              color="success"
                              min="1"
                              max="25"
                              v-validate="'required'"
                              v-model="planoGlobal.parcelas"
                            />
                          </vx-input-group>
                        </vs-row>
                      </div>
                    </div>
                  </VuePerfectScrollbar>
                </div>
              </form>
            </tab-content>
          </form-wizard>
        </div>
        <div class="vx-col w-1/4 flex">
          <vx-card card-background="dark" style="color: white; font-size: 20px">
            <VuePerfectScrollbar
              class="scroll-resumo-prospeccao"
              :settings="settings"
            >
              <vs-list-header
                title="RESUMO"
                style="color: white"
                class="px-2 py-0"
              ></vs-list-header>
              <vs-divider
                border-style="dashed"
                color="primary"
                class="py-0 my-1"
              ></vs-divider>
              <div v-if="cliente != null">
                <vs-row>
                  <vs-col
                    vs-w="2"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <vs-icon
                      icon="person"
                      size="medium"
                      color="primary"
                    ></vs-icon>
                  </vs-col>
                  <vs-col
                    vs-w="10"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <ModalVisualizarCliente
                      @update="getCliente()"
                      :id_cliente="cliente.id"
                      :nomeCliente="cliente.nome_fantasia"
                      textColor="primary"
                      class="px-2"
                    />
                  </vs-col>
                </vs-row>
                <vs-divider
                  border-style="dashed"
                  color="primary"
                  class="py-0 my-1 my-1"
                ></vs-divider>
              </div>
              <div v-if="segmento != ''">
                <vs-row>
                  <vs-col
                    vs-w="2"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <vs-icon
                      icon="house"
                      size="medium"
                      color="primary"
                    ></vs-icon>
                  </vs-col>
                  <vs-col
                    vs-w="10"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <span class="px-2">{{ segmento }}</span>
                  </vs-col>
                </vs-row>
                <vs-divider
                  border-style="dashed"
                  color="primary"
                  class="py-0 my-1 my-1"
                ></vs-divider>
              </div>
              <div v-if="prospeccao.id_controle != null">
                <vs-row>
                  <vs-col
                    vs-w="2"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <vs-icon
                      icon="bug_report"
                      size="medium"
                      color="primary"
                    ></vs-icon>
                  </vs-col>
                  <vs-col vs-w="10">
                    <span class="px-2">{{ controleNome }}</span>
                  </vs-col>
                </vs-row>
                <vs-divider
                  border-style="dashed"
                  color="primary"
                  class="py-0 my-1"
                ></vs-divider>
              </div>
              <div v-if="prospeccao.obs != ''">
                <vs-row>
                  <vs-col
                    vs-w="2"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <vs-icon
                      icon="notes"
                      size="medium"
                      color="primary"
                    ></vs-icon>
                  </vs-col>
                  <vs-col vs-w="10">
                    <span class="px-2">{{ prospeccao.obs }}</span>
                  </vs-col>
                </vs-row>
                <vs-divider
                  border-style="dashed"
                  color="primary"
                  class="py-0 my-1"
                ></vs-divider>
              </div>
              <div v-if="areasSelecionadas.length > 0">
                <vs-row>
                  <vs-col
                    vs-w="2"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <vs-icon
                      icon="meeting_room"
                      size="medium"
                      color="primary"
                    ></vs-icon>
                  </vs-col>
                  <vs-col
                    vs-w="10"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <vs-col vs-w="12">
                      <span
                        v-for="(item, index) in areasSelecionadas"
                        v-bind:key="index"
                        class="px-2"
                      >
                        {{ item.qtd }} {{ item.nome }}(s)
                        <br />
                      </span>
                    </vs-col>
                  </vs-col>
                </vs-row>
                <vs-divider
                  border-style="dashed"
                  color="primary"
                  class="py-0 my-1"
                ></vs-divider>
              </div>
              <div v-if="enderecos.length > 0">
                <vs-row>
                  <vs-col
                    vs-w="2"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <vs-icon
                      icon="place"
                      size="medium"
                      color="primary"
                    ></vs-icon>
                  </vs-col>
                  <vs-col
                    vs-w="10"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <vs-col vs-w="12">
                      <span
                        v-for="(item, index) in enderecos"
                        v-bind:key="index"
                        class="px-2"
                      >
                        {{ item.nome }}
                        <br />
                      </span>
                    </vs-col>
                  </vs-col>
                </vs-row>
                <vs-divider
                  border-style="dashed"
                  color="primary"
                  class="py-0 my-1"
                ></vs-divider>
              </div>
              <div v-if="levantamentos.length > 0">
                <vs-row>
                  <vs-col
                    vs-w="2"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <vs-icon
                      icon="info"
                      size="medium"
                      color="primary"
                    ></vs-icon>
                  </vs-col>
                  <vs-col
                    vs-w="10"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <vs-col vs-w="12">
                      <span
                        v-for="(item, index) in levantamentos"
                        v-bind:key="index"
                        class="px-2"
                      >
                        {{ item.id }} - {{ item.data_competencia }}
                        <br />
                      </span>
                    </vs-col>
                  </vs-col>
                </vs-row>
                <vs-divider
                  border-style="dashed"
                  color="primary"
                  class="py-0 my-1"
                ></vs-divider>
              </div>
            </VuePerfectScrollbar>
          </vx-card>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import EditEndereco from "./../cliente/components/EditEndereco.vue"
import { FormWizard, TabContent } from "vue-form-wizard"
import "vue-form-wizard/dist/vue-form-wizard.min.css"
import { Validator } from "vee-validate"
import ModalVisualizarCliente from "./../cliente/visualizar.vue"
import components from "@/components/default/exports.js"
import { Datetime } from "vue-datetime"
import "vue-datetime/dist/vue-datetime.css"
import selectTipoLigacao from "./../contrato/selectTipoLigacao.vue"
import Areas from "./../area/components/areas.vue"
import Extras from "./../area/components/extras.vue"
import reagendarProspeccaoModal from "./../kanban/components/reagendarProspeccaoModal"

const dict = {
  custom: {}
}
Validator.localize("en", dict)

export default {
  name: "Prospeccao",
  data () {
    return {
      reRenderDesconto: 0,
      areaComponent: null,
      reRenderArea: 1,
      wizardTab: 0,
      planoTrimestral: {
        horas_iniciais: 1,
        horas_manutencao: 1,
        id_tipo_faturamento: 1,
        parcelas: 3,
        tecnicos_iniciais: 1,
        tecnicos_manutencao: 1,
        periodo_manutencao: 30,
        desconto: 0,
        garantia: 3,
        validade: 3,
        aplicacoes: 2
      },
      planoSemestral: {
        horas_iniciais: 1,
        horas_manutencao: 1,
        id_tipo_faturamento: 2,
        parcelas: 6,
        tecnicos_iniciais: 1,
        tecnicos_manutencao: 1,
        periodo_manutencao: 30,
        desconto: 0,
        garantia: 6,
        validade: 6,
        aplicacoes: 4
      },
      planoAnualFisico: {
        horas_iniciais: 1,
        horas_manutencao: 1,
        id_tipo_faturamento: 3,
        parcelas: 12,
        tecnicos_iniciais: 1,
        tecnicos_manutencao: 1,
        periodo_manutencao: 30,
        desconto: 0,
        garantia: 12,
        validade: 12,
        aplicacoes: 7
      },
      planoMensal: {
        horas_iniciais: 1,
        id_tipo_faturamento: 4,
        parcelas: 12,
        horas_manutencao: 1,
        tecnicos_iniciais: 1,
        tecnicos_manutencao: 1,
        periodo_manutencao: 30,
        desconto: 0,
        garantia: 6,
        validade: 6,
        aplicacoes: 4
      },
      planoAnualJuridico: {
        horas_iniciais: 1,
        horas_manutencao: 1,
        id_tipo_faturamento: 5,
        parcelas: 12,
        tecnicos_iniciais: 1,
        tecnicos_manutencao: 1,
        periodo_manutencao: 30,
        desconto: 0,
        garantia: 12,
        validade: 12,
        aplicacoes: 12
      },
      planoGlobal: {
        horas_iniciais: 1,
        horas_manutencao: 1,
        id_tipo_faturamento: 6,
        parcelas: 1,
        tecnicos_iniciais: 1,
        tecnicos_manutencao: 1,
        periodo_manutencao: 30,
        desconto: 0,
        garantia: 6,
        validade: 6,
        aplicacoes: 6
      },

      prospeccao: {
        id_tipo_contato: 1,
        id_empresa: null,
        id_segmento: null,
        id_controle: null,
        modulos: 0,
        armadilhas: 0,
        carros: 0
      },
      reRender: 0,
      segmento: this.$route.query.segmento,
      segmentos: {},
      controles: {},
      areas: {},
      tabClass: "tab-height",
      scrollResumoProspeccao: null,
      scrollPlanosProspeccao: null,
      areaQtd: 1,
      areaNome: null,
      areasSelecionadas: [],
      areasLevantamento: [],
      obs: "",
      contatos: [],
      loading: false,
      informacoesTecnicas: {},
      cliente: { tipo_pessoa: null, tipo: 1, id_tipo_contato: 1 },
      enderecos: [],
      isSidebarActive: true,
      tipo_pessoa: [
        { nome: "PESSOA FÍSICA", id: 1 },
        { nome: "PESSOA JURÍDICA", id: 2 }
      ],
      turnos: [
        { nome: "manha" },
        { nome: "almoco" },
        { nome: "tarde" },
        { nome: "noite" },
        { nome: "madrugada" }
      ],
      levantamentos: [],
      levantamento: {},
      comodo: { qtd: 1 },
      contrato: {},
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 1000
      },
      empresas: {},
      assunto: {},
      retorno: false,
      controleNome: null,
      provenientes: {},
      limites_desconto: {},
      parametro: {},
      reRenderLev: 0
    }
  },
  methods: {
    /////////////////////////////////////////////////////
    // GET FUNCTIONS //
    /////////////////////////////////////////////////////
    async getFunctions () {
      await this.$vs.loading()
      try {
        if (this.$route.query.id_contrato == null) {
          await this.getCliente()
        } else {
          await this.getProspeccao()
          await this.getClienteByPropseccao()
          if (this.$route.query.id_lev != null) {
            await this.getInfoLev(this.$route.query.id_lev)
          }
        }
        await this.getEnderecosCliente()
        await this.getProvenientes()
        await this.getEmpresas()
        await this.getSegmentos()
        await this.getControles()
        await this.getLimitesDesconto()
        this.parametro = await this.$parametros.get()
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.reRender += await 1
        await this.$vs.loading.close()
      }
    },
    async getInfoLev (id_lev) {
      try {
        const res = await this.$http.post(`getInfoLev`, { id: id_lev })
        this.areasLevantamento = res.areas
        this.setValuesInfoLev(res.infoLev)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.reRenderLev++
      }
    },
    async setValuesInfoLev (info) {
      if (info.horas_iniciais) {
        this.planoTrimestral.horas_iniciais = info.horas_iniciais
        this.planoSemestral.horas_iniciais = info.horas_iniciais
        this.planoAnualFisico.horas_iniciais = info.horas_iniciais
        this.planoAnualJuridico.horas_iniciais = info.horas_iniciais
        this.planoGlobal.horas_iniciais = info.horas_iniciais
        this.planoMensal.horas_iniciais = info.horas_iniciais
      }
      if (info.horas_manutencao) {
        this.planoTrimestral.horas_manutencao = info.horas_manutencao
        this.planoSemestral.horas_manutencao = info.horas_manutencao
        this.planoAnualFisico.horas_manutencao = info.horas_manutencao
        this.planoAnualJuridico.horas_manutencao = info.horas_manutencao
        this.planoGlobal.horas_manutencao = info.horas_manutencao
        this.planoMensal.horas_manutencao = info.horas_manutencao
      }
      if (info.tecnicos_iniciais) {
        this.planoTrimestral.tecnicos_iniciais = info.tecnicos_iniciais
        this.planoSemestral.tecnicos_iniciais = info.tecnicos_iniciais
        this.planoAnualFisico.tecnicos_iniciais = info.tecnicos_iniciais
        this.planoAnualJuridico.tecnicos_iniciais = info.tecnicos_iniciais
        this.planoGlobal.tecnicos_iniciais = info.tecnicos_iniciais
        this.planoMensal.tecnicos_iniciais = info.tecnicos_iniciais
      }
      if (info.tecnicos_manutencao) {
        this.planoTrimestral.tecnicos_manutencao = info.tecnicos_manutencao
        this.planoSemestral.tecnicos_manutencao = info.tecnicos_manutencao
        this.planoAnualFisico.tecnicos_manutencao = info.tecnicos_manutencao
        this.planoAnualJuridico.tecnicos_manutencao = info.tecnicos_manutencao
        this.planoGlobal.tecnicos_manutencao = info.tecnicos_manutencao
        this.planoMensal.tecnicos_manutencao = info.tecnicos_manutencao
      }

      this.prospeccao.fog = info.fog ? info.fog : false
      this.prospeccao.escada = info.escada ? info.escada : false
      this.prospeccao.obs_tecnica = info.obs ? info.obs : ""
    },
    async getCliente () {
      try {
        this.cliente = await this.$http.get(
          `cliente/` + this.$route.query.cliente
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getClienteByPropseccao () {
      try {
        this.cliente = await this.$http.get(
          `cliente/` + this.prospeccao.id_cliente
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getProspeccao () {
      try {
        this.prospeccao = await this.$http.get(
          `contrato/` + this.$route.query.id_contrato
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getProvenientes () {
      try {
        this.provenientes = await this.$http.get(`proviniente`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getSegmentos () {
      try {
        this.segmentos = await this.$http.get(`segmento`)
        this.prospeccao.id_segmento = await this.cliente.id_segmento
        if (this.prospeccao.id_segmento == null) {
          this.prospeccao.id_segmento = await this.segmentos[0].id
        } else {
          this.segmento = this.segmentos.find(
            segmento => segmento.id == this.prospeccao.id_segmento
          ).nome
        }
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getEmpresas () {
      try {
        this.empresas = await this.$http.get(`empresa`)
        if (this.prospeccao.id_empresa == null) {
          this.prospeccao.id_empresa = await this.empresas[0].id
        }
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getAreas () {
      try {
        this.areas = await this.$http.get(`area`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getControles () {
      try {
        this.controles = await this.$http.get(`controle`)
        if (this.prospeccao.id_controle == null) {
          this.prospeccao.id_controle = await this.controles[0].id
          this.controleNome = await this.controles[0].nome
        } else {
          this.controleNome = this.controles.find(
            controle => controle.id == this.prospeccao.id_controle
          ).nome
        }
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getEnderecosCliente () {
      try {
        this.enderecos = await this.$http.get(
          "enderecosCliente/" + this.cliente.id
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getLimitesDesconto () {
      try {
        this.limites_desconto = await this.$http.get("limites_desconto")
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getLevantamentosContrato () { },
    /////////////////////////////////////////////////////
    // VALIDATE STEPS //
    /////////////////////////////////////////////////////
    async validarSegmento () {
      if (
        !this.prospeccao.id_segmento ||
        !this.cliente.tipo_pessoa ||
        !this.prospeccao.id_empresa ||
        !this.cliente.id_proviniente
      ) {
        await this.$vs.notify({
          title: "Preencha todos os campos",
          text: "Não deixe campos vazios!",
          color: "danger"
        })
        return false
      } else {
        if (!this.cliente.id_tipo_contato) {
          await this.$vs.notify({
            title: "Escolha o tipo de prospecção!",
            text: "Tipo de prospecção é obrigatório!",
            color: "danger"
          })
          return false
        } else {
          return true
        }
      }
    },
    async validarControle () {
      if (!this.prospeccao.id_controle) {
        await this.$vs.notify({
          title: "Preencha todos os campos",
          text: "Não deixe campos vazios!",
          color: "danger"
        })
        return false
      } else {
        return true
      }
    },
    async validarEndereco () {
      if (this.enderecos.length == 0) {
        await this.$vs.notify({
          title: "Adicione ao menos 1 endereço",
          text: "Para prosseguir, adicione 1 endereço!",
          color: "danger"
        })
        return false
      } else {
        return true
      }
    },
    async validarPlanos () {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-5").then(result => {
          if (result) {
            resolve(true)
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos dos planos",
              text: "Os campos não devem ser vazios!",
              color: "danger"
            })
            reject("error")
          }
        })
      })
    },
    /////////////////////////////////////////////////////
    // SET FUNCTIONS //
    /////////////////////////////////////////////////////
    async sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async selectInteraction (tipo) {
      this.prospeccao.id_tipo_contato = tipo
      this.contrato.id_tipo_contato = tipo
      this.cliente.id_tipo_contato = tipo
    },
    async saveLev (lev) {
      this.levantamentos.push(lev)
    },
    async removLev (lev) {
      let remove = this.levantamentos.map(x => x.id).indexOf(lev.id)
      this.levantamentos.splice(remove, 1)
    },
    async logAssunto (res, id_assunto, texto) {
      try {
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "cadastrar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: texto
        }
        await this.$logger(logData)
        await this.$http.post("assuntoCliente", {
          id_assuntos: id_assunto,
          id_cliente: res.id_cliente,
          id_contrato: res.id,
          id_estagio: res.id_estagio,
          status: 2,
          tipo: this.cliente.id_tipo_contato,
          id_colaborador: window.localStorage.getItem("id"),
          obs: texto
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async addArea () {
      let id_area = this.areas.map(x => x.nome).indexOf(this.areaNome)
      this.areasSelecionadas.push({
        nome: this.areaNome,
        qtd: this.areaQtd,
        id_area: id_area + 1
      })
      this.areaQtd = 1
    },
    async removerArea (id) {
      let area = this.areasSelecionadas.map(x => x.id_area).indexOf(id)
      this.areasSelecionadas.splice(area, 1)
    },
    async atualizarAreasSelecionadas (areasSelecionadas) {
      this.areasSelecionadas = areasSelecionadas
    },
    async changeControle (event) {
      this.controleNome = this.controles.find(
        controle => controle.id == event
      ).nome
    },
    async changeSegmento (event) {
      this.segmento = this.segmentos.find(
        segmento => segmento.id == event
      ).nome
    },
    async updateExtras (prospeccao) {
      this.prospeccao.fog = prospeccao.fog
      this.prospeccao.escada = prospeccao.escada
      this.prospeccao.modulos = prospeccao.modulos
      this.prospeccao.armadinhas = prospeccao.armadinhas
    },
    async saveEndereco (endereco) {
      this.enderecos.push({
        id_estado: endereco.id_estado,
        id_bairro: endereco.id_bairro,
        id_cidade: endereco.id_cidade,
        id_segmento: endereco.id_segmento,
        logradouro: endereco.logradouro,
        numero: endereco.numero,
        nome: endereco.nome,
        cep: endereco.cep,
        complemento: endereco.complemento,
        lixeira: false
      })
    },
    /////////////////////////////////////////////////////
    // SAVE FUNCTIONS //
    /////////////////////////////////////////////////////
    async postProspeccao () {
      this.prospeccao.id_cliente = this.cliente.id
      this.prospeccao.id_colaborador = await localStorage.getItem("id")
      if (this.levantamentos.length > 0) {
        this.prospeccao.id_estagio = 1
        this.prospeccao.id_status = 7
        this.cliente.id_status = 3
      } else {
        let vencimento = await this.$formartData.somarDias(
          new Date(),
          parseInt(this.parametro.validade_proposta)
        )
        this.prospeccao.id_estagio = 2
        this.prospeccao.data_proposta = this.$formartData.dataHoje()
        this.prospeccao.data_vencimento = await this.$formartData.formatar(
          vencimento,
          1
        )
        this.prospeccao.id_status = 1
        this.cliente.id_status = 1
      }
      try {
        let planos = await this.mountPlanos()
        this.contrato = await this.$http.post("postProspeccao", {
          contrato: this.prospeccao,
          areas: this.areasSelecionadas,
          planos: planos,
          cliente: this.cliente
        })
        if (!(this.levantamentos.length > 0)) {
          let textoAssunto = `Cadastro da prospecção do cliente N° ${this.cliente.id}`
          await this.logAssunto(this.contrato, 2, textoAssunto)
          textoAssunto = `Cadastro da proposta N° ${this.contrato.id}`
          await this.logAssunto(this.contrato, 5, textoAssunto)
        } else {
          let textoAssunto = `Cadastro da prospecção do cliente  N° ${this.cliente.id}`
          await this.logAssunto(this.contrato, 2, textoAssunto)
        }
        await this.$vs.notify({
          title: "Successo",
          text: "Prospecção Cadastrada Com successo",
          color: "success"
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
      return 1
    },
    async putProspeccao () {
      this.prospeccao.id_cliente = this.cliente.id
      this.prospeccao.id_colaborador = await localStorage.getItem("id")
      if (this.levantamentos.length > 0) {
        this.prospeccao.id_estagio = 1
        this.prospeccao.id_status = 7
        this.cliente.id_status = 3
      } else {
        let vencimento = await this.$formartData.somarDias(
          new Date(),
          parseInt(this.parametro.validade_proposta)
        )
        this.prospeccao.id_estagio = 2
        this.prospeccao.data_proposta = this.$formartData.dataHoje()
        this.prospeccao.data_vencimento = vencimento
        this.prospeccao.id_status = 1
        this.cliente.id_status = 1
      }
      try {
        let planos = await this.mountPlanos()
        this.contrato = await this.$http.put("putProspeccao", {
          contrato: this.prospeccao,
          areas: this.areasSelecionadas,
          planos: planos,
          cliente: this.cliente
        })
        if (!(this.levantamentos.length > 0)) {
          let textoAssunto = `Cadastro da proposta N° ${this.contrato.id}`
          await this.logAssunto(this.contrato, 5, textoAssunto)
        } else {
          let textoAssunto = `Cadastro da prospecção do cliente N° ${this.cliente.id}`
          await this.logAssunto(this.contrato, 2, textoAssunto)
        }
        await this.$vs.notify({
          title: "Successo",
          text: "Prospecção Cadastrada Com successo",
          color: "success"
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
      return 1
    },
    async mountPlanos () {
      if (!(this.levantamentos.length > 0)) {
        let planos = []
        this.planoTrimestral.id_cliente = this.cliente.id
        this.planoSemestral.id_cliente = this.cliente.id
        this.planoAnualFisico.id_cliente = this.cliente.id
        this.planoAnualJuridico.id_cliente = this.cliente.id
        this.planoGlobal.id_cliente = this.cliente.id
        this.planoMensal.id_cliente = this.cliente.id
        delete this.planoTrimestral.valor
        delete this.planoSemestral.valor
        delete this.planoAnualFisico.valor
        delete this.planoAnualJuridico.valor
        delete this.planoGlobal.valor
        delete this.planoMensal.valor
        if (this.cliente.tipo_pessoa == 1) {
          await this.setValoresPlanoPadrao(this.planoSemestral)
          planos = [
            this.planoTrimestral,
            this.planoSemestral,
            this.planoAnualFisico
          ]
        } else {
          await this.setValoresPlanoPadrao(this.planoGlobal)
          planos = [
            this.planoMensal,
            this.planoAnualJuridico,
            this.planoGlobal
          ]
        }
        return planos
      }
    },
    async setValoresPlanoPadrao (plano) {
      this.prospeccao.desconto = plano.desconto
      this.prospeccao.horas_iniciais = plano.horas_iniciais
      this.prospeccao.tecnicos_iniciais = plano.tecnicos_iniciais
      this.prospeccao.horas_manutencao = plano.horas_manutencao
      this.prospeccao.tecnicos_manutencao = plano.tecnicos_manutencao
      this.prospeccao.aplicacoes = plano.aplicacoes
      this.prospeccao.periodo_manutencao = plano.periodo_manutencao
      this.prospeccao.garantia = plano.garantia
      this.prospeccao.valor = plano.valor
      this.prospeccao.intervalo_parcela = 30
      this.prospeccao.id_tipo_faturamento = plano.id_tipo_faturamento
      this.prospeccao.parcelas = plano.parcelas
      this.prospeccao.valor =
        200 * (plano.horas_iniciais * plano.tecnicos_iniciais) +
        200 *
        (plano.horas_manutencao *
          plano.tecnicos_manutencao *
          (plano.aplicacoes - 1))
    },
    async putLevantamento () {
      try {
        this.levantamentos.forEach(async levantamento => {
          levantamento.id_contrato = this.contrato.id
          const res = await this.$http.put(
            "levantamento/" + levantamento.id,
            levantamento
          )
          let logData = {
            id_colaborador: localStorage.getItem("id"),
            funcao: "atualizar",
            setor: "comercial",
            ip: window.localStorage.getItem("ip"),
            texto: "Cadastro do levantamento N°" + res.id
          }
          await this.$logger(logData)
          await this.$vs.notify({
            title: "Successo",
            text: "Levantamentos associados com Sucesso",
            color: "success"
          })
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async goTo () {
      await this.$router.push("/kanban")
    },
    async confirmarProspeccao () {
      await this.$vs.loading()
      try {
        if (this.$route.query.id_contrato != null) {
          await this.putProspeccao()
        } else {
          await this.postProspeccao()
        }
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
      if (!(this.levantamentos.length > 0)) {
        await this.$router.push("/contrato?id=" + this.contrato.id)
      } else {
        await this.putLevantamento()
        await this.sleep(750)
        await this.$router.push("/kanban")
      }
      await this.$vs.loading.close()
    },
    async verifyDesconto (limite, plano) {
      if (limite < plano.desconto) {
        plano.desconto = limite
        this.$vs.notify({
          title: "Desconto inválido!",
          text:
            "O desconto dado é maior que o limite deste tipo de faturamento!",
          color: "danger"
        })
      }
      this.reRenderDesconto += 1
    },
    async imageHeight () {
      // if (window.innerHeight > 700) {
      //   this.tabClass = "tab-height-lg";
      //   this.scrollResumoProspeccao = "scroll-resumo-prospeccao-lg";
      //   this.scrollPlanosProspeccao = "scroll-planos-prospeccao-lg";
      // } else {
      //    this.tabClass = "tab-height";
      //   this.scrollResumoProspeccao = "scroll-resumo-prospeccao";
      //   this.scrollPlanosProspeccao = "scroll-planos-prospeccao";
      // }
    }
  },
  async mounted () {
    await this.imageHeight()
    await this.getFunctions()
  },
  components: {
    FormWizard,
    Datetime,
    TabContent,
    ModalVisualizarCliente,
    EditEndereco,
    selectTipoLigacao,
    ...components,
    Areas,
    Extras,
    reagendarProspeccaoModal
  }
};
</script>

<style lang="scss">
.tab-height {
  height: 340px;
}
.sizeScrollArea {
  height: 20vh !important;
}
.scroll-resumo-prospeccao {
  height: 60vh !important;
}
.scroll-planos-prospeccao {
  max-height: 33vh !important;
}
.sizeScrollAddress {
  height: calc(10%);
}
.vs-list--title {
  font-size: 17px !important;
}
.vs-list--icon i {
  font-size: 2.2rem !important;
  color: #ffca00;
}
.btn.active {
  background-color: #021a33 !important;
}
.vs-select--options:focus {
  background-color: #021a33;
}
.optionSelect:hover {
  color: yellow !important;
}

.tab-height-lg {
  height: 500px;
}
.sizeScrollArea-lg {
  height: 20vh !important;
}
.scroll-resumo-prospeccao-lg {
  height: 60vh !important;
}
.scroll-planos-prospeccao-lg {
  max-height: 33vh !important;
}
.sizeScrollAddress-lg {
  height: calc(10%);
}
</style>
