<template>
  <div class="vs-con-loading__container" id="headArea">
    <vs-row vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
      <vs-col vs-w="3.5" vs-type="flex" vs-justify="center" vs-align="center">
        <div class="w-full mx-5">
          <label style="color: black; font-size: 0.85rem">Áreas</label>
          <br />
          <el-select
            filterable
            clearable
            class="w-full"
            placeholder="Selecione..."
            :popper-append-to-body="true"
            v-model="idAreaSelecionada"
          >
            <el-option v-for="area in areas" :value="area.id" :label="area.nome" :key="area.id"></el-option>
          </el-select>
        </div>
      </vs-col>
      <vs-col vs-w="3.5" vs-type="flex" vs-justify="center" vs-align="center">
        <div class="pt-5">
          <vs-input-number
            color="success"
            v-validate="'required|numeric'"
            label="Quantidade."
            min="1"
            value="1"
            max="1"
            v-model="areaQtd"
            name="areaQtd"
          />
        </div>
      </vs-col>
      <vs-col vs-w="2.5" vs-type="flex" vs-justify="center" vs-align="flex-start">
        <div class="pt-5">
          <vs-button
            class="pt-3"
            :disabled="
              idAreaSelecionada != null && idAreaSelecionada ? false : true
            "
            color="success"
            @click.prevent="addArea()"
            type="relief"
          >Adicionar</vs-button>
        </div>
      </vs-col>
    </vs-row>
    <div class="flex justify-center" v-if="areasSelecionadas.length > 0">
      <vs-divider color="success" class="py-0 mt-5 mb-0"></vs-divider>
    </div>
    <div class="scroll-areas" v-else>
      <vs-col class="mt-8" vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
        <vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon>
      </vs-col>
      <vs-col class="mt-2" vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
        <span>Sem Áreas</span>
      </vs-col>
    </div>
    <vs-row vs-type="flex" vs-justify="center" v-if="areasSelecionadas.length > 0">
      <vs-col vs-w="7" vs-type vs-justify="center" vs-align="center">
        <VuePerfectScrollbar class="scroll-areas" :settings="settings">
          <vs-list-item
            class="m-3"
            style
            v-for="(data, index) in areasSelecionadas"
            v-bind:key="index"
            :title="data.qtd + ' ' + data.nome + '(s)'"
          >
            <vs-button
              @click="removerArea(data.id_area)"
              type="relief"
              size="small"
              color="danger"
            >Remover</vs-button>
          </vs-list-item>
        </VuePerfectScrollbar>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
export default {
  props: {
    id: null,
    areasLevantamento: null
  },
  data() {
    return {
      areas: {},
      areaQtd: 1,
      areasSelecionadas: [],
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 1000
      },
      idAreaSelecionada: null
    };
  },
  methods: {
    async getAreas() {
      try {
        this.areas = await this.$http.get("area");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getAreasSelecionadas() {
      try {
        this.areasSelecionadas = await this.$http.get(
          "getAreasSelecionadas/" + this.id
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async addArea() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          let areaSelecionada = this.areas.find(
            area => area.id == this.idAreaSelecionada
          );
          if (!(this.areasSelecionadas.constructor === Array)) {
            this.areasSelecionadas = [];
          }
          await this.areasSelecionadas.push({
            nome: areaSelecionada.nome,
            qtd: 1,
            id_area: areaSelecionada.id
          });
          this.areaQtd = 1;
        }
        await this.$emit("addAreas", this.areasSelecionadas);
      });
      console.log(this.areaNome);
    },
    async removerArea(id) {
      let area = this.areasSelecionadas.map(x => x.id_area).indexOf(id);
      this.areasSelecionadas.splice(area, 1);
      this.$emit("addAreas", this.areasSelecionadas);
    }
  },
  async mounted() {
    await this.$vs.loading({ container: "#headArea", scale: 0.6 });
    await this.getAreas();
    if (this.areasLevantamento != null) {
      this.areasSelecionadas = this.areasLevantamento;
    } else {
      await this.getAreasSelecionadas();
    }
    this.$emit("addAreas", this.areasSelecionadas);
    await this.$vs.loading.close("#headArea > .con-vs-loading");
  },
  components: {
    ...components
  }
};
</script>
<style lang="scss">
.scroll-areas {
  max-height: 18vh !important;
}
</style>
